import {
  faArrowLeft,
  faBars,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  Hidden,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { makeStyles } from "@mui/styles";
import React from "react";
import Link from "react-scroll/modules/components/Link";
import history from "../history";
import { Links } from "./../utills/Links";
import CButton from "./custom/CButton";
import CTypography from "./custom/CTypography";
import LogoImage from "../img/newlogo.jpeg";
import Main_logo from "../img/main_logo.svg";

export const APP_BAR_HEIGHT = 96;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

const useStyle = makeStyles((theme) => ({
  calllogo: {
    borderRadius: 50,
    color: "#2f2f2f",
    fontWeight: "bold",
    backgroundColor: theme.palette.primary.main,
    width: 50,
    height: 50,
    cursor: "pointer",
    "&:hover": {
      boxShadow: "1px 2px 10px black",
    },
  },

  drawer: {
    zIndex: theme.zIndex.drawer,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerPaper: {
    width: "100vw",
    // background: 'red'

    background: "#252525",
  },
  drawerContainer: {
    overflow: "hidden",
    color: theme.palette.primary.main,
    // color: 'white'
  },
  drawerIcon: {
    // color: theme.palette.common.white,
    width: 24,
    height: 24,
  },
  drawerText: {
    fontSize: 18,
    fontWeight: "bold",
    letterSpacing: 8,
    //  color: theme.palette.common.white,
    cursor: "pointer",
  },
}));

const Navbar = (props) => {
  const classes = useStyle();
  const [openDrawer, setOpenDrawer] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };
  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleOnClickCareer = () => {
    history.push(Links.career);
    if (openDrawer) {
      setOpenDrawer(false);
    }
  };

  const handleGotoHome = () => {
    history.push(Links.home);
    if (openDrawer) {
      setOpenDrawer(false);
    }
  };

  function getDrawer() {
    return (
      <div className={classes.drawerContainer} role="presentation">
        <Grid container justifyContent="flex-start">
          <Grid item style={{ paddingTop: 24, paddingLeft: 24 }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleDrawerClose}
              className={classes.drawerIcon}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ height: "80vh" }}
        >
          <Grid item style={{ width: "100%" }}>
            <Link to="about-us" smooth={true} duration={1000}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography
                    onClick={handleGotoHome}
                    className={classes.drawerText}
                  >
                    ABOUT US
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Link to="service" smooth={true} duration={1000}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography
                    onClick={handleGotoHome}
                    className={classes.drawerText}
                  >
                    SERVICES
                  </Typography>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography
                  onClick={handleOnClickCareer}
                  className={classes.drawerText}
                >
                  CAREER
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }

  return (
    <Grid container direction="column" justifyContent="center">
      {openDrawer ? (
        <Hidden mdUp implementation="css">
          <nav className={classes.drawer} aria-label="drawer menus">
            {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

            <Drawer
              // container={container}
              variant="temporary"
              anchor={"left"}
              open={openDrawer}
              onClose={handleDrawerClose}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {getDrawer()}
            </Drawer>
          </nav>
        </Hidden>
      ) : null}
      <Grid item>
        <ElevationScroll {...props}>
          <AppBar
            position="fixed"
            sx={{ height: APP_BAR_HEIGHT, backgroundColor: "white" }}
          >
            <Container maxWidth="xl" sx={{ my: "auto" }}>
              <Toolbar disableGutters>
                <Hidden mdUp>
                  <FontAwesomeIcon
                    icon={faBars}
                    size="xl"
                    style={{ color: "#0086FF" }}
                    onClick={handleDrawerOpen}
                  />
                </Hidden>
                <img
                  src={Main_logo}
                  onClick={() => {
                    history.push(Links.home);
                  }}
                  style={{
                    height: 90,
                    width: 250,
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  alt=""
                />
                <Box sx={{ flexGrow: 1 }} />
                <Hidden smDown>
                  <Link to="about-us" smooth={true} duration={1000}>
                    <Button
                      size="large"
                      color="primary"
                      sx={{
                        fontFamily: "Lato-Regular",
                        fontSize: 18,
                        fontWeight: "bold",
                        textTransform: "none",
                      }}
                      onClick={handleGotoHome}
                    >
                      About us
                    </Button>
                  </Link>
                  <Link to="service" smooth={true} duration={1000}>
                    <Button
                      size="large"
                      color="primary"
                      sx={{
                        fontFamily: "Lato-Regular",
                        fontSize: 18,
                        fontWeight: "bold",
                        ml: 2,
                        textTransform: "none",
                      }}
                      onClick={handleGotoHome}
                    >
                      Services
                    </Button>
                  </Link>
                  <Button
                    size="large"
                    color="primary"
                    onClick={handleOnClickCareer}
                    sx={{
                      mr: 2,
                      ml: 2,
                      fontFamily: "Lato-Regular",
                      fontSize: 18,
                      fontWeight: "bold",
                      textTransform: "none",
                    }}
                  >
                    Career
                  </Button>

                  <Link to="contact-us" smooth={true} duration={1000}>
                    <CButton
                      size="large"
                      color="primary"
                      variant="contained"
                      sx={{
                        mr: 2,
                        fontFamily: "Lato-Regular",
                        fontSize: 18,
                        fontWeight: "bold",
                      }}
                      onClick={handleGotoHome}
                    >
                      Contact us
                    </CButton>
                  </Link>
                </Hidden>
                <Hidden mdUp>
                  <Grid item>
                    <Link to="contact-us" smooth={true} duration={1000}>
                      <Stack
                        alignItems="center"
                        justifyContent="center"
                        className={classes.calllogo}
                      >
                        <FontAwesomeIcon size="lg" icon={faPhone} />
                      </Stack>
                    </Link>
                  </Grid>
                </Hidden>
              </Toolbar>
            </Container>
          </AppBar>
        </ElevationScroll>
      </Grid>
    </Grid>
  );
};

export default Navbar;
